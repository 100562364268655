<template>
    <v-container>
        <v-card  min-height="400" class="mt-5 bg-white" flat>
            <div class="p-3">
                <v-overlay color="white" :absolute="true" v-if="overlay" z-index="1">
                    <div class="d-flex align-center">
                        <div class="text-center">
                            <v-progress-circular size="55" width="1" color="#0A009B" indeterminate v-if="load"   > </v-progress-circular>
                            <v-icon  class="border rounded-circle p-2" :color="color" size="36" v-if="icon_show"> {{ icon }} </v-icon>
                            <h5 :class="'mt-4 text-' + text_color"> {{ message }} </h5>
                            <div class="border-top mt-10" v-if="success">
                                <p class="black--text mb-2 mt-5">To show list of companies click the button below.</p>
                                <v-btn class="border" text color="#0A009B" @click="companies"> Company List </v-btn>
                            </div>
                            <div class="border-top mt-10" v-if="status_failed">
                                <p class="black--text mb-2 mt-5">The verification code already activated or missing. To check if the company is activated click the button below. </p>
                                <v-btn  text color="#0A009B" @click="companies"> Company List </v-btn>
                            </div>
                        </div>
                    </div>
                </v-overlay>
            </div>
        </v-card>

    </v-container>
</template>
<script>
export default {
    props: ['myInfo'],
    data()
    {
        return {
            color : 'info',
            token : null,
            message : 'Loading Information',
            load : true,
            icon_show : false,
            icon : 'mdi-check',
            overlay : true,
            form : null,
            status_init : true,
            text_color : 'info',
            success : false,
            status_failed : false,
            
        }
    },
    created()
    {
        
        if(!localStorage.getItem('token') )
        {
            let c = 2
            setInterval( () => {
                c-- 
                if(c <= 0)
                {
                    if(this.status_init)
                    {
                        this.init()
                    }
                }
            }, 1000)
        }
    },
    watch: {
        myInfo(val)
        {
            if(val)
            {
                let c = 2
                setInterval( () => {
                    c-- 
                    if(c <= 0)
                    {
                        if(this.status_init)
                        {
                            this.init()
                        }
                    }
                }, 1000)
            }
        }
    },
    methods : {
        init()
        {
            this.status_init = false
            if (this.$route.query.token) {
                this.token = this.$route.query.token
                this.verifyToken();
            }else{
                this.failed()
            }
        },
        verifyToken()
        {
            if(this.token)
            {
                this.message = 'Verifying'
                this.axios.post('verify_company_token?token='+this.token)
                .then( ( {data} ) => {
                    if(data.company)
                    {
                        this.form = data.company
                        this.form.token = data.token
                        this.saveData()
                    }else{
                        this.failed()
                    }
                } )
                .catch( () => {
                   this.failed()
                })
            }else{
                this.load = false
                this.icon_show = true
                this.icon = 'mdi-close'
                this.color = 'error'
                this.message = 'Error'
                this.redirect()
            }
        },
        failed()
        {
            this.status_failed = true
            this.load = false
            this.icon_show = true
            this.icon = 'mdi-close'
            this.color = 'error',
            this.text_color = 'danger'
            this.message = 'Failed to Verify'
        },
        saveData()
        {
            let url = null
            if(this.form.type == 'owner')
            {
              
               url = 'companyregistration'
            }

            if(this.form.type == 'affiliated')
            {
                url = 'companies'
            }
            if(url)
            {
                this.axios.post(url, this.form)
                .then( () => {
                    this.icon_show = true
                    this.icon = 'mdi-check'
                    this.color = 'success'
                    this.text_color = 'success'
                    this.message = 'Company Successfully Verified'
                    this.success = true

                    if(this.myInfo)
                    {
                       this.$emit('refresh', true)
                    }
                })
                .catch( () => {
                   this.failed()
                })
                .finally( () => {
                    this.load = false
                    
                })
            }
        },
        companies()
        {
            if(!this.myInfo)
            {
                window.location.href = this.$ETOUSOFT_LOGIN_URL
            }else{
                this.$router.push({path : '/account/mycompanies'})
            }
        }
        
    }
}   
</script>
